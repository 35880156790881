.ic {
  width: 36px;
  height: 36px;
  flex-shrink: 0;
  background: url('data:image/svg+xml,<svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg"><circle cx="18" cy="18" r="18" fill="%236747C7" fill-opacity="0.24"/><path d="M18 14C16.343 14 15 14.895 15 16C15 17.105 16.343 18 18 18C19.657 18 21 18.895 21 20C21 21.105 19.657 22 18 22M18 14C19.11 14 20.08 14.402 20.599 15M18 14V13M18 14V22M18 22V23M18 22C16.89 22 15.92 21.598 15.401 21M27 18C27 19.1819 26.7672 20.3522 26.3149 21.4442C25.8626 22.5361 25.1997 23.5282 24.364 24.364C23.5282 25.1997 22.5361 25.8626 21.4442 26.3149C20.3522 26.7672 19.1819 27 18 27C16.8181 27 15.6478 26.7672 14.5558 26.3149C13.4639 25.8626 12.4718 25.1997 11.636 24.364C10.8003 23.5282 10.1374 22.5361 9.68508 21.4442C9.23279 20.3522 9 19.1819 9 18C9 15.6131 9.94821 13.3239 11.636 11.636C13.3239 9.94821 15.6131 9 18 9C20.3869 9 22.6761 9.94821 24.364 11.636C26.0518 13.3239 27 15.6131 27 18Z" stroke="%236747C7" stroke-linecap="round" stroke-linejoin="round"/></svg>');
}

.trsr {
  /*
	display:grid;
	grid-template-columns*/
  display: flex;
  justify-content: space-between;

  padding: 14px 18px;
  align-items: center;
  margin-bottom: 6px;

  border-radius: 14px;
  background: #fff;

  .col {
    display: grid;
    grid-template-columns: 1fr;

    .h {
      color: #6b6b6b;
      font-family: Inter;
      font-size: 10px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }
    .s {
      color: #000;
      font-family: Inter;
      font-size: 10px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
    }
  }

  .but {
    padding: 10px 1rem;
    text-transform: capitalize;
    &.pending {
      border-radius: 8px;
      background: rgba(255, 172, 51, 0.33);

      color: #ffac33;
      font-family: Inter;
      font-size: 13px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
    }

    &.confirmed {
      border-radius: 8px;
      background: rgba(0, 97, 60, 0.17);

      color: #2ecc71;
      font-family: Inter;
      font-size: 13px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
    }

    &.declined {
      border-radius: 8px;
      background: rgba(196, 0, 0, 0.2);

      color: #c40000;
      font-family: Inter;
      font-size: 13px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
    }
  }

  /*

ALTER TABLE `transactions_upgrade_deposit` ADD `type` VARCHAR(36) NULL DEFAULT 'n/a' AFTER `user_id`;

ALTER TABLE `transactions_transfer` ADD `status` VARCHAR(36) NULL DEFAULT 'n/a' AFTER `user_id`;


ALTER TABLE `transactions_transfer` CHANGE `status` `status` ENUM('pending','confirmed','declined') CHARACTER SET utf8mb4 COLLATE utf8mb4_0900_ai_ci NULL DEFAULT 'pending';*/
}
