@use '../../../_mixins.scss' as *;

.h {
  color: #313131;
  font-family: Inter;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-bottom: 15px;
}
.s {
  color: #b7b7b7;
  font-family: Inter;
  font-size: 10px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-bottom: 11px;
  padding-left: $left-shift;
}

.empty {
  display: grid;
  justify-content: center;
  align-items: center;

  height: 30vh;
  width: 100%;

  .c {
    .h {
      color: #19202f;
      font-family: Inter;
      text-align: center;
      font-size: 15px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      margin-bottom: 1rem;
    }
    .s {
      color: #aaa;
      text-align: center;
      font-family: Inter;
      font-size: 11px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
    }
  }
}

.entry {
  border-radius: 20px;
  background: #fff;
  padding: 1rem;

  display: flex;
  justify-content: space-between;
  margin-bottom: 0.3rem;

  .rig {
    display: flex;
    justify-content: flex-end;
    align-items: end;
    flex-direction: column;

    .amount {
      color: #313131;
      font-family: Inter;
      font-size: 12px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
    }

    .successful {
      padding: 0.2rem 0.5rem;
      border-radius: 3px;
      background: rgba(0, 97, 60, 0.17);
      color: #2ecc71;
      font-family: Inter;
      font-size: 7px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      text-transform: capitalize;
    }
    .in_progress {
      padding: 0.2rem 0.5rem;
      color: #ffd700;
      font-family: Inter;
      font-size: 7px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      border-radius: 3px;
      background: rgba(255, 215, 0, 0.2);
      text-transform: capitalize;
    }
    .failed {
      padding: 0.2rem 0.5rem;
      border-radius: 3px;
      background: rgba(196, 0, 0, 0.2);
      color: #c40000;
      font-family: Inter;
      font-size: 7px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      text-transform: capitalize;
    }
  }

  .lef {
    display: grid;
    align-items: center;
    grid-template-columns: 39px 1fr;
    gap: 0.5rem;

    .t1 {
      color: #313131;
      font-family: Inter;
      font-size: 11px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
    .t2 {
      color: #aeaeae;
      font-family: Inter;
      font-size: 7px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }

    .credit {
      width: 38.076px;
      height: 38.076px;
      flex-shrink: 0;
      background: url('data:image/svg+xml,<svg width="39" height="39" viewBox="0 0 39 39" fill="none" xmlns="http://www.w3.org/2000/svg"><circle cx="19.0381" cy="19.0381" r="19.0381" fill="%236747C7" fill-opacity="0.24"/><path d="M19.62 16.7467V19.62M19.62 19.62V22.4933M19.62 19.62H22.4933M19.62 19.62H16.7467M28.24 19.62C28.24 20.752 28.017 21.8729 27.5838 22.9187C27.1506 23.9646 26.5157 24.9148 25.7153 25.7153C24.9148 26.5157 23.9646 27.1506 22.9187 27.5838C21.8729 28.017 20.752 28.24 19.62 28.24C18.488 28.24 17.3671 28.017 16.3213 27.5838C15.2754 27.1506 14.3252 26.5157 13.5247 25.7153C12.7243 24.9148 12.0894 23.9646 11.6562 22.9187C11.223 21.8729 11 20.752 11 19.62C11 17.3338 11.9082 15.1413 13.5247 13.5247C15.1413 11.9082 17.3338 11 19.62 11C21.9062 11 24.0987 11.9082 25.7153 13.5247C27.3318 15.1413 28.24 17.3338 28.24 19.62Z" stroke="%236747C7" stroke-linecap="round" stroke-linejoin="round"/></svg>');
    }
    .debit {
      width: 38.076px;
      height: 38.076px;
      flex-shrink: 0;
      background: url('data:image/svg+xml,<svg width="39" height="39" viewBox="0 0 39 39" fill="none" xmlns="http://www.w3.org/2000/svg"><circle cx="19.0381" cy="19.0381" r="19.0381" fill="%236747C7" fill-opacity="0.24"/><path d="M21.5525 19.3973H17.242M25.863 19.3973C25.863 20.2464 25.6958 21.0872 25.3709 21.8716C25.0459 22.6561 24.5697 23.3689 23.9693 23.9693C23.3689 24.5697 22.6561 25.0459 21.8716 25.3709C21.0872 25.6958 20.2464 25.863 19.3973 25.863C18.5482 25.863 17.7074 25.6958 16.9229 25.3709C16.1385 25.0459 15.4257 24.5697 14.8253 23.9693C14.2249 23.3689 13.7486 22.6561 13.4237 21.8716C13.0988 21.0872 12.9315 20.2464 12.9315 19.3973C12.9315 17.6825 13.6127 16.0379 14.8253 14.8253C16.0379 13.6127 17.6825 12.9315 19.3973 12.9315C21.1121 12.9315 22.7567 13.6127 23.9693 14.8253C25.1818 16.0379 25.863 17.6825 25.863 19.3973Z" stroke="%236747C7" stroke-linecap="round" stroke-linejoin="round"/></svg>');
    }
  }
}
