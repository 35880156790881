@use '../../../_mixins.scss' as *;

.h {
  color: #313131;
  font-family: Inter;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-bottom: 11px;
  padding-left: $left-shift;
}
