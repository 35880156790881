.label {
  color: #aaa;
  font-family: Inter;
  font-size: 8px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;

  color: #313131;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.c {
  margin: 10px 0px;
  border-radius: 10px;
  background: #eeeef0;

  padding: 7px 26px;

  display: grid;
  grid-template-columns: 1fr 16px;
  gap: 1rem;

  input {
    color: #19202f;
    font-family: Inter;
    font-size: 13px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    width: 100%;

    background: transparent;
    outline: none;
    border: 0px solid transparent;
  }

  .e {
    display: flex;
    flex-direction: column;
    cursor: pointer;
    .icon {
      width: 18px;
      height: 18px;
      flex-shrink: 0;
      background: url('data:image/svg+xml,<svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M5 13H3C2.46957 13 1.96086 12.7893 1.58579 12.4142C1.21071 12.0391 1 11.5304 1 11V3C1 2.46957 1.21071 1.96086 1.58579 1.58579C1.96086 1.21071 2.46957 1 3 1H11C11.5304 1 12.0391 1.21071 12.4142 1.58579C12.7893 1.96086 13 2.46957 13 3V5M7 17H15C15.5304 17 16.0391 16.7893 16.4142 16.4142C16.7893 16.0391 17 15.5304 17 15V7C17 6.46957 16.7893 5.96086 16.4142 5.58579C16.0391 5.21071 15.5304 5 15 5H7C6.46957 5 5.96086 5.21071 5.58579 5.58579C5.21071 5.96086 5 6.46957 5 7V15C5 15.5304 5.21071 16.0391 5.58579 16.4142C5.96086 16.7893 6.46957 17 7 17Z" stroke="%2319202F" stroke-linecap="round" stroke-linejoin="round"/></svg>');
    }
    .tx {
      color: #000;
      font-family: Inter;
      font-size: 8px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
  }
}
