.b {
  border-radius: 20px;
  background: #fff;
  padding: 1rem;
  margin-bottom: 1rem;

  .h {
    color: #6747c7;
    font-family: Inter;
    font-size: 15px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;

    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .s2 {
    color: #19202f;
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
}

.mar {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 9px;

  border-radius: 11px;
  border: 1px solid #2ecc71;
  background: rgba(217, 217, 217, 0);

  .icon {
    width: 11.927px;
    height: 8.75px;
    flex-shrink: 0;
    background: url('data:image/svg+xml,<svg width="14" height="11" viewBox="0 0 14 11" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M8.83875 5.375C8.83875 5.87228 8.6412 6.34919 8.28957 6.70083C7.93794 7.05246 7.46103 7.25 6.96375 7.25C6.46646 7.25 5.98955 7.05246 5.63792 6.70083C5.28629 6.34919 5.08875 5.87228 5.08875 5.375C5.08875 4.87772 5.28629 4.40081 5.63792 4.04917C5.98955 3.69754 6.46646 3.5 6.96375 3.5C7.46103 3.5 7.93794 3.69754 8.28957 4.04917C8.6412 4.40081 8.83875 4.87772 8.83875 5.375Z" stroke="%2331AE66" stroke-linecap="round" stroke-linejoin="round"/><path d="M1 5.375C1.79625 2.83937 4.16562 1 6.96375 1C9.7625 1 12.1312 2.83937 12.9275 5.375C12.1312 7.91063 9.7625 9.75 6.96375 9.75C4.16562 9.75 1.79625 7.91063 1 5.375Z" stroke="%2331AE66" stroke-linecap="round" stroke-linejoin="round"/></svg>');
  }
  .text {
    color: #2ecc71;
    font-family: Inter;
    font-size: 10px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;

    background: transparent;
    border: 0px solid transparent;
    outline: none;
  }
}
