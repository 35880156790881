.he {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 66px;

  img {
    width: 123px;
    height: 40px;
    flex-shrink: 0;
  }

  a {
    color: #6747c7;
    font-family: Inter;
    font-size: 15px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-decoration: none;

    cursor: pointer;

    padding: 10px 24px;

    border-radius: 20px;
    border: 1px solid rgba(103, 71, 199, 0.24);
    background: #fff;
  }
}
