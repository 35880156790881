@use '../../_mixins.scss' as *;

.in {
  border-radius: 10px;
  background: #6747c7;
  color: #fff;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  padding: $input-con-padding;
  width: 100%;
  text-align: center;
  margin: 15px 0px; /*
  margin: 26px 0px;*/
  border: 0px solid transparent;

  cursor: pointer;
}
