@use '../_mixins.scss' as *;

.n {
  color: #19202f;
  font-family: Inter;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;

  padding: 6px 21px;
}

.up {
  width: 15.166px;
  height: 13.92px;
  flex-shrink: 0;
  background: url('data:image/svg+xml,<svg width="16" height="14" viewBox="0 0 16 14" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M5.83986 1.02C6.60486 -0.34 8.56186 -0.34 9.32586 1.02L14.9059 10.94C15.6559 12.274 14.6929 13.92 13.1639 13.92H2.00286C0.472856 13.92 -0.490144 12.274 0.259856 10.94L5.83986 1.02ZM8.58286 10.921C8.58286 11.1862 8.4775 11.4406 8.28996 11.6281C8.10243 11.8156 7.84807 11.921 7.58286 11.921C7.31764 11.921 7.06329 11.8156 6.87575 11.6281C6.68821 11.4406 6.58286 11.1862 6.58286 10.921C6.58286 10.6558 6.68821 10.4014 6.87575 10.2139C7.06329 10.0264 7.31764 9.921 7.58286 9.921C7.84807 9.921 8.10243 10.0264 8.28996 10.2139C8.4775 10.4014 8.58286 10.6558 8.58286 10.921ZM7.58286 2.921C7.31764 2.921 7.06329 3.02636 6.87575 3.21389C6.68821 3.40143 6.58286 3.65578 6.58286 3.921V6.921C6.58286 7.18622 6.68821 7.44057 6.87575 7.62811C7.06329 7.81564 7.31764 7.921 7.58286 7.921C7.84807 7.921 8.10243 7.81564 8.28996 7.62811C8.4775 7.44057 8.58286 7.18622 8.58286 6.921V3.921C8.58286 3.65578 8.4775 3.40143 8.28996 3.21389C8.10243 3.02636 7.84807 2.921 7.58286 2.921Z" fill="%23D84848"/></svg>');
}

.header2 {
  background: #fff; /*
  position: fixed;*/
  width: 100%;

  .c {
    display: flex;
    justify-content: space-between;
    max-width: 500px;
    margin: auto;
    padding: 0.5rem;
    align-items: center;

    position: sticky;

    .mnl {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      gap: 1rem;

      .logo {
        width: 150px;
        height: 36px;
        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
      }
    }

    .us {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      gap: 1rem;

      .notifnobell {
        width: 18px;
        height: 20px;
        flex-shrink: 0;
        background: url('data:image/svg+xml,<svg width="18" height="20" viewBox="0 0 18 20" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M12 15H17L15.595 13.595C15.4063 13.4063 15.2567 13.1822 15.1546 12.9357C15.0525 12.6891 15 12.4249 15 12.158V9C15.0002 7.75894 14.6156 6.54834 13.8992 5.53489C13.1829 4.52144 12.17 3.75496 11 3.341V3C11 2.46957 10.7893 1.96086 10.4142 1.58579C10.0391 1.21071 9.53043 1 9 1C8.46957 1 7.96086 1.21071 7.58579 1.58579C7.21071 1.96086 7 2.46957 7 3V3.341C4.67 4.165 3 6.388 3 9V12.159C3 12.697 2.786 13.214 2.405 13.595L1 15H6M12 15V16C12 16.7956 11.6839 17.5587 11.1213 18.1213C10.5587 18.6839 9.79565 19 9 19C8.20435 19 7.44129 18.6839 6.87868 18.1213C6.31607 17.5587 6 16.7956 6 16V15M12 15H6" stroke="%23313131" stroke-linecap="round" stroke-linejoin="round"/></svg>');
      }
      .notifi {
        width: 24px;
        height: 23px;
        flex-shrink: 0;

        background: url('data:image/svg+xml,<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M12 19H17L15.595 17.595C15.4063 17.4063 15.2567 17.1822 15.1546 16.9357C15.0525 16.6891 15 16.4249 15 16.158V13C15.0002 11.7589 14.6156 10.5483 13.8992 9.53489C13.1829 8.52144 12.17 7.75496 11 7.341V7C11 6.46957 10.7893 5.96086 10.4142 5.58579C10.0391 5.21071 9.53043 5 9 5C8.46957 5 7.96086 5.21071 7.58579 5.58579C7.21071 5.96086 7 6.46957 7 7V7.341C4.67 8.165 3 10.388 3 13V16.159C3 16.697 2.786 17.214 2.405 17.595L1 19H6M12 19V20C12 20.7956 11.6839 21.5587 11.1213 22.1213C10.5587 22.6839 9.79565 23 9 23C8.20435 23 7.44129 22.6839 6.87868 22.1213C6.31607 21.5587 6 20.7956 6 20V19M12 19H6" stroke="%23313131" stroke-linecap="round" stroke-linejoin="round"/><circle cx="20" cy="4" r="4" fill="%23C40000"/></svg>');
      }
      .img {
        width: 38.51px;
        height: 38.51px;
        flex-shrink: 0;

        background: url('data:image/svg+xml,<svg width="39" height="39" viewBox="0 0 39 39" fill="none" xmlns="http://www.w3.org/2000/svg"><g clip-path="url(%23clip0_0_851)"><mask id="mask0_0_851" style="mask-type:luminance" maskUnits="userSpaceOnUse" x="0" y="0" width="39" height="39"><path d="M38.5105 0H0V38.5105H38.5105V0Z" fill="white"/></mask><g mask="url(%23mask0_0_851)"><path opacity="0.4" d="M19.2552 38.0076C29.8895 38.0076 38.5105 29.5576 38.5105 19.1336C38.5105 8.70986 29.8895 0.259705 19.2552 0.259705C8.62085 0.259705 0 8.70986 0 19.1336C0 29.5576 8.62085 38.0076 19.2552 38.0076Z" fill="%23AAAAAA"/><path d="M19.2553 9.56433C15.2694 9.56433 12.0345 12.7352 12.0345 16.642C12.0345 20.4734 15.0961 23.5876 19.159 23.7009H19.3323H19.4671H19.5056C23.3951 23.5688 26.4567 20.4734 26.476 16.642C26.476 12.7352 23.2411 9.56433 19.2553 9.56433Z" fill="%2319202F"/><path d="M32.3116 33.0059C28.8842 36.1013 24.3015 38.0076 19.2566 38.0076C14.2117 38.0076 9.62898 36.1013 6.20154 33.0059C6.66366 31.2885 7.91525 29.7219 9.74451 28.514C15.0012 25.0789 23.5505 25.0789 28.7687 28.514C30.6172 29.7219 31.8495 31.2885 32.3116 33.0059Z" fill="%2319202F"/></g></g><defs><clipPath id="clip0_0_851"><rect width="38.5105" height="38.5105" fill="white"/></clipPath></defs></svg>');

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          border-radius: 50%;
        }
      }
    }

    .menu {
      margin-left: $left-shift;
      width: 16px;
      height: 15px;
      flex-shrink: 0;
      background: url('data:image/svg+xml,<svg width="18" height="15" viewBox="0 0 18 15" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M1 1.20331H17M1 7.48099H9M1 13.7587H17" stroke="%23313131" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg>'); /*
      background: url('data:image/svg+xml,<svg width="18" height="14" viewBox="0 0 18 14" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M1 1H17M1 7H9M1 13H17" stroke="%23313131" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg>');*/
    }
  }
}
