.body {
  /*
  padding: 2rem;
  padding-top: 5rem;
  padding: 1rem;
  */

  padding: 1rem;
  min-width: 390px;
  margin-top: 1rem;
  max-width: 500px;
  margin: auto;
  overflow-y: scroll;
  background: #f7f7f7;
  height: 100%;
}
.body::-webkit-scrollbar {
  display: none;
}

.ncdis {
  border-radius: 3px;
  background: #d84848;
  width: 18px;
  height: 18px;
  flex-shrink: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 1rem;

  color: #fff;
  font-family: Inter;
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
/* Hide scrollbar for IE, Edge and Firefox */
.body {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.das {
  background: #f7f7f7;
  height: 100vh;

  /*
  display:grid;
    grid-template-rows: 60px calc( 100vh - 170px) 95px;*/

  display: flex;
  height: 100vh;
  justify-content: flex-start;
  flex-direction: column;
}

.sidenav {
  height: 100%;
  width: 0;
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  background-color: #fff;
  overflow-x: hidden;
  transition: 0.5s;
  padding-top: 30px;

  .c {
    overflow-y: scroll;
  }

  .cimg {
    height: 36px;
    flex-shrink: 0;
    padding-left: 11px;
    padding-bottom: 16px;
    img {
      height: 100%;
      object-fit: cover;
    }
  }
}

.sidenav a {
  padding: 2px;
  /*
  padding: 8px 8px 8px 8px; 
  padding: 8px 8px 8px 32px;*/
  text-decoration: none;
  padding-right: 1rem;

  text-transform: capitalize;
  color: #fff;
  font-family: Inter;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  /*
  font-size: 25px;*/
  color: #000;
  display: flex;
  justify-content: flex-start;
  gap: 1rem;
  align-items: center;
  transition: 0.3s;
}

/*
.sidenav a:hover {
  color: #f1f1f1;
}*/

.sidenav .closebtn {
  position: absolute;
  top: 0;
  right: 25px;
  font-size: 36px;
  margin-left: 50px;
}

@media screen and (max-height: 450px) {
  .sidenav {
    padding-top: 15px;
  }
  .sidenav a {
    font-size: 18px;
  }
}
