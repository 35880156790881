.bg {
  height: 0%;
  width: 0%;
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  display: none;
  /*
  max-width: 500px;
  margin: auto;*/

  &.displayN {
    height: 100vh;

    /**/
    width: 100%;
    margin-top: 1rem; /*
    margin-left: 1rem;*/
    display: flex !important;
    justify-content: center;
    height: auto;

    .dBox {
      max-width: 310px;
      display: flex;
      justify-content: flex-start;
      gap: 0.5rem;
      align-items: center; /*
      padding: 12px 28px;*/
      padding: 0.5rem;
      border-radius: 11px;

      &.success {
        background: #31ae66;
      }
      &.error {
        background: #c75757;
      }
      &.warning {
        background: #ffac33;
      }

      .msg {
        color: #fff;
        font-family: Inter;
        font-size: 15px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
      }
    }
  }
}

.waitN {
  width: 22px;
  height: 22px;
  flex-shrink: 0;
  background: url('data:image/svg+xml,<svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M11 22C13.9174 22 16.7153 20.8411 18.7782 18.7782C20.8411 16.7153 22 13.9174 22 11C22 8.08262 20.8411 5.28473 18.7782 3.22183C16.7153 1.15893 13.9174 0 11 0C8.08262 0 5.28473 1.15893 3.22183 3.22183C1.15893 5.28473 0 8.08262 0 11C0 13.9174 1.15893 16.7153 3.22183 18.7782C5.28473 20.8411 8.08262 22 11 22ZM12.375 5.5C12.375 5.13533 12.2301 4.78559 11.9723 4.52773C11.7144 4.26987 11.3647 4.125 11 4.125C10.6353 4.125 10.2856 4.26987 10.0277 4.52773C9.76987 4.78559 9.625 5.13533 9.625 5.5V11C9.62508 11.3646 9.76999 11.7143 10.0279 11.9721L13.9164 15.862C14.0441 15.9898 14.1958 16.0911 14.3627 16.1602C14.5296 16.2294 14.7085 16.265 14.8892 16.265C15.0699 16.265 15.2488 16.2294 15.4157 16.1602C15.5826 16.0911 15.7342 15.9898 15.862 15.862C15.9898 15.7342 16.0911 15.5826 16.1602 15.4157C16.2294 15.2488 16.265 15.0699 16.265 14.8892C16.265 14.7085 16.2294 14.5296 16.1602 14.3627C16.0911 14.1958 15.9898 14.0441 15.862 13.9164L12.375 10.4308V5.5Z" fill="white"/></svg>');
}
.cautionN {
  width: 19.17px;
  height: 17.595px;
  flex-shrink: 0;
  background: url('data:image/svg+xml,<svg width="20" height="18" viewBox="0 0 20 18" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M7.38178 1.28932C8.34877 -0.429772 10.8225 -0.429772 11.7882 1.28932L18.8415 13.8285C19.7895 15.5148 18.5723 17.5954 16.6396 17.5954H2.53168C0.597707 17.5954 -0.619559 15.5148 0.328467 13.8285L7.38178 1.28932ZM10.849 13.8045C10.849 14.1398 10.7159 14.4613 10.4788 14.6983C10.2417 14.9354 9.92023 15.0686 9.58499 15.0686C9.24975 15.0686 8.92824 14.9354 8.69118 14.6983C8.45413 14.4613 8.32096 14.1398 8.32096 13.8045C8.32096 13.4693 8.45413 13.1478 8.69118 12.9107C8.92824 12.6737 9.24975 12.5405 9.58499 12.5405C9.92023 12.5405 10.2417 12.6737 10.4788 12.9107C10.7159 13.1478 10.849 13.4693 10.849 13.8045ZM9.58499 3.69225C9.24975 3.69225 8.92824 3.82542 8.69118 4.06247C8.45413 4.29953 8.32096 4.62104 8.32096 4.95628V8.74838C8.32096 9.08363 8.45413 9.40514 8.69118 9.64219C8.92824 9.87924 9.24975 10.0124 9.58499 10.0124C9.92023 10.0124 10.2417 9.87924 10.4788 9.64219C10.7159 9.40514 10.849 9.08363 10.849 8.74838V4.95628C10.849 4.62104 10.7159 4.29953 10.4788 4.06247C10.2417 3.82542 9.92023 3.69225 9.58499 3.69225Z" fill="%23FFAC33"/></svg>');
}
.okN {
  width: 22px;
  height: 22px;
  flex-shrink: 0;
  background: url('data:image/svg+xml,<svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M11 22C13.9174 22 16.7153 20.8411 18.7782 18.7782C20.8411 16.7153 22 13.9174 22 11C22 8.08262 20.8411 5.28473 18.7782 3.22183C16.7153 1.15893 13.9174 0 11 0C8.08262 0 5.28473 1.15893 3.22183 3.22183C1.15893 5.28473 0 8.08262 0 11C0 13.9174 1.15893 16.7153 3.22183 18.7782C5.28473 20.8411 8.08262 22 11 22ZM16.0971 9.22213C16.3476 8.9628 16.4862 8.61547 16.4831 8.25495C16.4799 7.89443 16.3353 7.54956 16.0804 7.29462C15.8254 7.03969 15.4806 6.89508 15.1201 6.89195C14.7595 6.88881 14.4122 7.02741 14.1529 7.27788L9.625 11.8058L7.84712 10.0279C7.5878 9.77741 7.24047 9.63882 6.87995 9.64195C6.51943 9.64508 6.17456 9.78969 5.91962 10.0446C5.66469 10.2996 5.52008 10.6444 5.51695 11.0049C5.51381 11.3655 5.65241 11.7128 5.90288 11.9721L8.65287 14.7221C8.91073 14.9799 9.2604 15.1247 9.625 15.1247C9.9896 15.1247 10.3393 14.9799 10.5971 14.7221L16.0971 9.22213Z" fill="white"/></svg>');
}
