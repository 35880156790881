.crypto {
  width: 36px;
  height: 36px;
  flex-shrink: 0;
  background: url('data:image/svg+xml,<svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg"><circle cx="18" cy="18" r="18" fill="%236747C7" fill-opacity="0.24"/><path d="M18 14C16.343 14 15 14.895 15 16C15 17.105 16.343 18 18 18C19.657 18 21 18.895 21 20C21 21.105 19.657 22 18 22M18 14C19.11 14 20.08 14.402 20.599 15M18 14V13M18 14V22M18 22V23M18 22C16.89 22 15.92 21.598 15.401 21M27 18C27 19.1819 26.7672 20.3522 26.3149 21.4442C25.8626 22.5361 25.1997 23.5282 24.364 24.364C23.5282 25.1997 22.5361 25.8626 21.4442 26.3149C20.3522 26.7672 19.1819 27 18 27C16.8181 27 15.6478 26.7672 14.5558 26.3149C13.4639 25.8626 12.4718 25.1997 11.636 24.364C10.8003 23.5282 10.1374 22.5361 9.68508 21.4442C9.23279 20.3522 9 19.1819 9 18C9 15.6131 9.94821 13.3239 11.636 11.636C13.3239 9.94821 15.6131 9 18 9C20.3869 9 22.6761 9.94821 24.364 11.636C26.0518 13.3239 27 15.6131 27 18Z" stroke="%236747C7" stroke-linecap="round" stroke-linejoin="round"/></svg>');
}
.inst {
  width: 36px;
  height: 36px;
  flex-shrink: 0;
  background: url('data:image/svg+xml,<svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg"><circle cx="18" cy="18" r="18" fill="%236747C7" fill-opacity="0.24"/><path d="M19 16V9L10 20H17V27L26 16H19Z" stroke="%236747C7" stroke-linecap="round" stroke-linejoin="round"/></svg>');
}
.cash {
  width: 36px;
  height: 36px;
  flex-shrink: 0;
  background: url('data:image/svg+xml,<svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg"><circle cx="18" cy="18" r="18" fill="%236747C7" fill-opacity="0.24"/><path d="M23 15V13C23 12.4696 22.7893 11.9609 22.4142 11.5858C22.0391 11.2107 21.5304 11 21 11H11C10.4696 11 9.96086 11.2107 9.58579 11.5858C9.21071 11.9609 9 12.4696 9 13V19C9 19.5304 9.21071 20.0391 9.58579 20.4142C9.96086 20.7893 10.4696 21 11 21H13M15 25H25C25.5304 25 26.0391 24.7893 26.4142 24.4142C26.7893 24.0391 27 23.5304 27 23V17C27 16.4696 26.7893 15.9609 26.4142 15.5858C26.0391 15.2107 25.5304 15 25 15H15C14.4696 15 13.9609 15.2107 13.5858 15.5858C13.2107 15.9609 13 16.4696 13 17V23C13 23.5304 13.2107 24.0391 13.5858 24.4142C13.9609 24.7893 14.4696 25 15 25ZM22 20C22 20.5304 21.7893 21.0391 21.4142 21.4142C21.0391 21.7893 20.5304 22 20 22C19.4696 22 18.9609 21.7893 18.5858 21.4142C18.2107 21.0391 18 20.5304 18 20C18 19.4696 18.2107 18.9609 18.5858 18.5858C18.9609 18.2107 19.4696 18 20 18C20.5304 18 21.0391 18.2107 21.4142 18.5858C21.7893 18.9609 22 19.4696 22 20Z" stroke="%236747C7" stroke-linecap="round" stroke-linejoin="round"/></svg>');
}

.card {
  width: 36px;
  height: 36px;
  flex-shrink: 0;
  background: url('data:image/svg+xml,<svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg"><circle cx="18" cy="18" r="18" fill="%236747C7" fill-opacity="0.24"/><path d="M9 16H27M13 21H14M18 21H19M12 25H24C24.7956 25 25.5587 24.6839 26.1213 24.1213C26.6839 23.5587 27 22.7956 27 22V14C27 13.2044 26.6839 12.4413 26.1213 11.8787C25.5587 11.3161 24.7956 11 24 11H12C11.2044 11 10.4413 11.3161 9.87868 11.8787C9.31607 12.4413 9 13.2044 9 14V22C9 22.7956 9.31607 23.5587 9.87868 24.1213C10.4413 24.6839 11.2044 25 12 25Z" stroke="%236747C7" stroke-linecap="round" stroke-linejoin="round"/></svg>');
}

.bank {
  width: 36px;
  height: 36px;
  flex-shrink: 0;
  background: url('data:image/svg+xml,<svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg"><circle cx="18" cy="18" r="18" fill="%236747C7" fill-opacity="0.24"/><path d="M14 19V22M18 19V22M22 19V22M9 26H27M9 15H27M9 12L18 8L27 12M10 15H26V26H10V15Z" stroke="%236747C7" stroke-linecap="round" stroke-linejoin="round"/></svg>');
}
