$left-shift: 24px;
$input-con-padding: 14px 26px;

@mixin icon-size($radius) {
  width: $radius;
  height: $radius;
  flex-shrink: 0;
}

@mixin placeholder {
  ::-webkit-input-placeholder {
    @content;
  }
  :-moz-placeholder {
    @content;
  }
  ::-moz-placeholder {
    @content;
  }
  :-ms-input-placeholder {
    @content;
  }
}

@mixin input2 {
  color: #6b6b6b;
  font-family: Inter;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  width: 100%;

  background: transparent;
  outline: none;
  border: 0px solid transparent;
}

@mixin input5 {
  ::-webkit-input-placeholder {
    @content;
  }
  :-moz-placeholder {
    @content;
  }
  ::-moz-placeholder {
    @content;
  }
  :-ms-input-placeholder {
    @content;
  }
}
