.he {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 66px;

  .back {
    cursor: pointer;
    width: 22px;
    height: 18px;
    flex-shrink: 0;
    background: url('data:image/svg+xml,<svg width="22" height="18" viewBox="0 0 22 18" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M9 16L2 9M2 9L9 2M2 9H20" stroke="black" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/></svg>');
  }

  a {
    color: #6747c7;
    font-family: Inter;
    font-size: 15px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-decoration: none;

    padding: 10px 24px;

    border-radius: 20px;
    border: 1px solid rgba(103, 71, 199, 0.24);
    background: #fff;
  }
}
