.z {
  /*
  position:sticky;
  margin-top: 5rem;
  margin-top: 80px;*/
  bottom: 10px;
  left: 0;
  width: 100%;

  .b {
    max-width: 500px;
    margin: auto;

    padding: 24px;

    .c {
      /*
      border-radius: 12px;
      background: #fff;*/

      padding: 1rem;

      color: #19202f;
      font-family: Inter;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;

      text-align: center;
    }
  }
}
