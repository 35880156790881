@use '../../_mixins.scss' as *;

.lable {
  color: #313131;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding-left: $left-shift;
}
.duo {
  margin-top: 10px;
  display: grid;
  grid-template-columns: 1fr 22px;
  gap: 1rem;
  margin-bottom: 11px;
  align-items: center;

  border-radius: 20px;
  border: 1px solid #aaa;
  background: rgba(255, 255, 255, 0);
  padding: $input-con-padding;

  input {
    @include input2;
  }

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus {
    -webkit-text-fill-color: #19202f;
    -webkit-box-shadow: 0 0 0px 40rem #eeeef0 inset;
  }
}

.cover {
  margin-top: 10px;
  border-radius: 20px;
  border: 1px solid #aaa;
  background: rgba(255, 255, 255, 0);

  padding: $input-con-padding;
  margin-bottom: 11px;

  input {
    @include input2;
  }

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus {
    -webkit-text-fill-color: #19202f;
    -webkit-box-shadow: 0 0 0px 40rem #eeeef0 inset;
  }
}

.duo2 {
  display: grid;
  grid-template-columns: 22px 1fr;
  gap: 1rem;
  margin-bottom: 11px;
  align-items: center;

  border-radius: 20px;
  border: 1px solid #aaa;
  background: rgba(255, 255, 255, 0);

  padding: $input-con-padding;

  input {
    @include input2;

    color: #19202f;
    font-family: Inter;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus {
    -webkit-text-fill-color: #19202f;
    -webkit-box-shadow: 0 0 0px 40rem #eeeef0 inset;
  }
}

.cover2 {
  border-radius: 20px;
  border: 1px solid #aaa;
  background: rgba(255, 255, 255, 0);

  padding: $input-con-padding;
  margin-bottom: 11px;

  input {
    @include input2;
  }

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus {
    -webkit-text-fill-color: #19202f;
    -webkit-box-shadow: 0 0 0px 40rem #eeeef0 inset;
  }
}
