@use '../../../../_mixins.scss' as *;

.t {
  color: #19202f;
  font-family: Inter;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-bottom: 11px;
  padding-left: $left-shift;
}
.s {
  color: #19202f;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.s2 {
  color: #19202f;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
