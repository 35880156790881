.cw {
  max-width: 500px;
  margin: auto;
  display: flex;
  justify-content: flex-end;

  .c {
    position: absolute;
    top: 45px;
    /*width: 238px;*/
    width: 208px;
    height: 201px;
    flex-shrink: 0;
    padding: 14px 26px;
    margin-right: 1rem;

    border-radius: 11px;
    border: 1px solid rgba(103, 71, 199, 0.24);
    background: #fff;

    .ubox {
      display: grid;
      grid-template-columns: 39px 1fr;
      gap: 1rem;
      align-items: center;

      .img {
        width: 38.51px;
        height: 38.51px;
        flex-shrink: 0;
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          border-radius: 50%;
        }
        background: url('data:image/svg+xml,<svg width="39" height="39" viewBox="0 0 39 39" fill="none" xmlns="http://www.w3.org/2000/svg"><g clip-path="url(%23clip0_57_265)"><mask id="mask0_57_265" style="mask-type:luminance" maskUnits="userSpaceOnUse" x="0" y="0" width="39" height="39"><path d="M38.5105 0H0V38.5105H38.5105V0Z" fill="white"/></mask><g mask="url(%23mask0_57_265)"><path opacity="0.4" d="M19.2552 38.0076C29.8895 38.0076 38.5105 29.5576 38.5105 19.1336C38.5105 8.70986 29.8895 0.259705 19.2552 0.259705C8.62085 0.259705 0 8.70986 0 19.1336C0 29.5576 8.62085 38.0076 19.2552 38.0076Z" fill="%23AAAAAA"/><path d="M19.2551 9.56433C15.2693 9.56433 12.0344 12.7352 12.0344 16.642C12.0344 20.4734 15.096 23.5876 19.1589 23.7009H19.3322H19.4669H19.5055C23.395 23.5688 26.4566 20.4734 26.4758 16.642C26.4758 12.7352 23.241 9.56433 19.2551 9.56433Z" fill="%2319202F"/><path d="M32.3118 33.0059C28.8843 36.1013 24.3016 38.0076 19.2567 38.0076C14.2118 38.0076 9.6291 36.1013 6.20166 33.0059C6.66379 31.2885 7.91537 29.7219 9.74463 28.514C15.0013 25.0789 23.5506 25.0789 28.7688 28.514C30.6173 29.7219 31.8496 31.2885 32.3118 33.0059Z" fill="%2319202F"/></g></g><defs><clipPath id="clip0_57_265"><rect width="38.5105" height="38.5105" fill="white"/></clipPath></defs></svg>');
      }

      .r {
        .n {
          text-transform: capitalize;
          color: #6747c7;
          font-family: Inter;
          font-size: 14px;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
        }
        .t {
          color: #6747c7;
          font-family: Inter;
          font-size: 11px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
        }
      }
    }

    .li {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 140px;
      margin-top: 0.8rem;
    }

    .ro {
      display: flex;
      text-decoration: none;

      align-items: center;
      gap: 0.4rem;
      cursor: pointer;

      .words {
        color: #000;
        font-family: Inter;
        font-size: 15px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }
    }
  }
}
