.logo {
  width: 186px;
  height: 61px;
  flex-shrink: 0;

  img {
    width: 100%;
    height: 100%;
  }
}

.sub {
  margin-top: 29px;
  margin-bottom: 11px;

  display: flex;
  align-items: center;
  gap: 1rem;
  .text {
    color: #6747c7;
    font-family: Inter;
    font-size: 20px;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
  }
}

.lock {
  width: 23px;
  height: 26px;
  flex-shrink: 0;
  background: url('data:image/svg+xml,<svg width="22" height="27" viewBox="0 0 22 27" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M10.6364 0C6.11759 0 2.45459 3.663 2.45459 8.18182V16.3636H5.72732V8.18182C5.72732 5.47118 7.92495 3.27273 10.6364 3.27273C13.3479 3.27273 15.5455 5.47118 15.5455 8.18182V16.3636H18.8182V8.18182C18.8182 3.663 15.1552 0 10.6364 0Z" fill="%23AAB8C2"/><path d="M21.2727 23.7273C21.2727 25.5346 19.8074 27 18 27H3.27273C1.46536 27 0 25.5346 0 23.7273V13.9091C0 12.1017 1.46536 10.6364 3.27273 10.6364H18C19.8074 10.6364 21.2727 12.1017 21.2727 13.9091V23.7273Z" fill="%23FFAC33"/></svg>');
}

.h {
  color: #19202f;
  font-family: Inter;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-bottom: 11px;
}

.s {
  color: #19202f;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 24px;
}

.remeber {
  display: flex;
  align-items: center;

  .l {
    display: grid;
    align-items: center;
    grid-template-columns: 20px auto;
    gap: 0.5rem;
    input {
      width: 20px;
      height: 20px;
      flex-shrink: 0;
    }
    .text {
      color: #19202f;
      font-family: Inter;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
  }
  /*.r{
	a{
	color: #6747C7;
font-family: Inter;
font-size: 12px;
font-style: normal;
font-weight: 400;
line-height: normal;
	}
	}*/
}

.note {
  margin-left: 30px;
  margin-bottom: 0.5rem;

  color: #19202f;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  .imp {
    color: #6747c7; /*
font-family: Inter;
font-size: 12px;
font-style: normal;
font-weight: 400;
line-height: normal;*/
    text-decoration: none;
  }
}
