@use '../../../../_mixins.scss' as *;

.h {
  color: #19202f;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 1rem;
}

.h2 {
  color: #19202f;
  font-family: Inter;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-top: 1rem;
  margin-bottom: 5px;
  padding-left: $left-shift;
}

.p2 {
  color: #19202f;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding-left: $left-shift;

  margin-bottom: 1rem;
}

.h3 {
  color: #19202f;
  font-family: Inter;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  padding-left: $left-shift;
  margin-bottom: 0.5rem;
}
