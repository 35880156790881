.auht {
  /*
  background: #f7f7f7;*/
  background: white;
  height: 100vh;
}
.body {
  padding: 1rem;
  max-width: 500px;
  margin: auto;
  overflow-y: scroll; /*
  background: #f7f7f7;
  padding-top:5rem;*/
}

.body::-webkit-scrollbar {
  display: none;
}

.imc {
  display: flex;
  align-items: center;
  justify-content: center;
  .im {
    width: 381px;
    height: 254px;
    flex-shrink: 0;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      flex-shrink: 0;
    }
  }
  margin-bottom: 1rem;
}

.h1 {
  color: #19202f;
  text-align: center;
  font-family: Inter;
  font-size: 25px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-bottom: 15px;

  .p {
    color: #6747c7;
    font-family: Inter;
    font-size: 25px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
}

.sub {
  color: #474747;
  text-align: center;
  font-family: Inter;
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-bottom: 1rem;
}

.sbtn {
  border-radius: 20px;
  background: #6747c7;
  padding: 1rem;
  text-align: center;

  color: #fff;
  font-family: Inter;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;

  margin-bottom: 1rem;
  display: block;
}

.h2 {
  color: #000;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-align: center;

  display: block;
  .p {
    color: #6747c7;
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-decoration-line: underline;
  }
  margin-bottom: 19px;
}

.h3 {
  color: #000;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-align: center;
  margin-bottom: 9px;

  width: 100%;
}

.h4 {
  color: #19202f;
  text-align: center;
  font-family: Inter;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 9px;
}
