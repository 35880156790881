@use '../../../_mixins.scss' as *;
.h {
  color: #313131;
  font-family: Inter;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  padding-left: $left-shift;
}

.duo {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1rem;
}
