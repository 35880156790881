.k {
  display: flex;
  justify-content: flex-start;
  width: 100%;
  gap: 0.5rem;
  align-items: center;

  padding: 8px 21px;

  &.active {
    border-radius: 10px;
    background: #6747c7;
    color: #fff;
    padding: 8px 11px;
    margin-right: 1rem;
    margin-left: 0.5rem;

    a {
      text-decoration: none;
    }
  }
}
