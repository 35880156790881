.address {
  width: 20px;
  height: 20px;
  flex-shrink: 0;
  background: url('data:image/svg+xml,<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M1 10L3 8M3 8L10 1L17 8M3 8V18C3 18.2652 3.10536 18.5196 3.29289 18.7071C3.48043 18.8946 3.73478 19 4 19H7M17 8L19 10M17 8V18C17 18.2652 16.8946 18.5196 16.7071 18.7071C16.5196 18.8946 16.2652 19 16 19H13M7 19C7.26522 19 7.51957 18.8946 7.70711 18.7071C7.89464 18.5196 8 18.2652 8 18V14C8 13.7348 8.10536 13.4804 8.29289 13.2929C8.48043 13.1054 8.73478 13 9 13H11C11.2652 13 11.5196 13.1054 11.7071 13.2929C11.8946 13.4804 12 13.7348 12 14V18C12 18.2652 12.1054 18.5196 12.2929 18.7071C12.4804 18.8946 12.7348 19 13 19M7 19H13" stroke="black" stroke-linecap="round" stroke-linejoin="round"/></svg>');
}

.phone {
  width: 20px;
  height: 20px;
  flex-shrink: 0;
  background: url('data:image/svg+xml,<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M1 3C1 2.46957 1.21071 1.96086 1.58579 1.58579C1.96086 1.21071 2.46957 1 3 1H6.28C6.48979 1.00016 6.69422 1.0663 6.86436 1.18905C7.03449 1.3118 7.1617 1.48496 7.228 1.684L8.726 6.177C8.8019 6.40534 8.79293 6.65339 8.70072 6.87564C8.60851 7.0979 8.43925 7.27945 8.224 7.387L5.967 8.517C7.07332 10.9655 9.03446 12.9267 11.483 14.033L12.613 11.776C12.7205 11.5607 12.9021 11.3915 13.1244 11.2993C13.3466 11.2071 13.5947 11.1981 13.823 11.274L18.316 12.772C18.5152 12.8383 18.6885 12.9657 18.8112 13.136C18.934 13.3064 19.0001 13.511 19 13.721V17C19 17.5304 18.7893 18.0391 18.4142 18.4142C18.0391 18.7893 17.5304 19 17 19H16C7.716 19 1 12.284 1 4V3Z" stroke="black" stroke-linecap="round" stroke-linejoin="round"/></svg>');
}

.email {
  width: 20px;
  height: 16px;
  flex-shrink: 0;
  background: url('data:image/svg+xml,<svg width="20" height="16" viewBox="0 0 20 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M1 4L8.89 9.26C9.21866 9.47928 9.6049 9.5963 10 9.5963C10.3951 9.5963 10.7813 9.47928 11.11 9.26L19 4M3 15H17C17.5304 15 18.0391 14.7893 18.4142 14.4142C18.7893 14.0391 19 13.5304 19 13V3C19 2.46957 18.7893 1.96086 18.4142 1.58579C18.0391 1.21071 17.5304 1 17 1H3C2.46957 1 1.96086 1.21071 1.58579 1.58579C1.21071 1.96086 1 2.46957 1 3V13C1 13.5304 1.21071 14.0391 1.58579 14.4142C1.96086 14.7893 2.46957 15 3 15Z" stroke="black" stroke-linecap="round" stroke-linejoin="round"/></svg>');
}

.hide {
  width: 21px;
  height: 20px;
  flex-shrink: 0;
  background: url('data:image/svg+xml,<svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M12.418 16.825C11.7999 16.9419 11.1721 17.0005 10.543 17C6.065 17 2.275 14.057 1 10C1.34327 8.9081 1.87199 7.88346 2.563 6.971M12.663 12.12L8.421 7.879C8.98366 7.31634 9.74678 7.00025 10.5425 7.00025C11.3382 7.00025 12.1013 7.31634 12.664 7.879C13.2267 8.44166 13.5428 9.20478 13.5428 10.0005C13.5428 10.7962 13.2267 11.5593 12.664 12.122L15.955 15.412M8.423 7.88L5.133 4.59M5.133 4.59L1.543 1M5.133 4.59C6.74536 3.54957 8.6241 2.9974 10.543 3C15.021 3 18.811 5.943 20.086 10C19.382 12.2305 17.9204 14.1446 15.954 15.411L19.543 19" stroke="black" stroke-linecap="round" stroke-linejoin="round"/></svg>');
}

.show {
  width: 21px;
  height: 16px;
  flex-shrink: 0;
  background: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="21" height="16" viewBox="0 0 21 16" fill="none"><path d="M13.542 8C13.542 8.79565 13.2259 9.55871 12.6633 10.1213C12.1007 10.6839 11.3376 11 10.542 11C9.74634 11 8.98328 10.6839 8.42067 10.1213C7.85806 9.55871 7.54199 8.79565 7.54199 8C7.54199 7.20435 7.85806 6.44129 8.42067 5.87868C8.98328 5.31607 9.74634 5 10.542 5C11.3376 5 12.1007 5.31607 12.6633 5.87868C13.2259 6.44129 13.542 7.20435 13.542 8Z" stroke="black" stroke-linecap="round" stroke-linejoin="round"/><path d="M1 8C2.274 3.943 6.065 1 10.542 1C15.02 1 18.81 3.943 20.084 8C18.81 12.057 15.02 15 10.542 15C6.065 15 2.274 12.057 1 8Z" stroke="black" stroke-linecap="round" stroke-linejoin="round"/></svg>');
}
