@use '../../../_mixins.scss' as *;
.h0 {
  color: #313131;
  font-family: Inter;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;

  margin: 1rem 0rem;
  padding-left: $left-shift;
}

.empty {
  display: flex;
  justify-content: center;
  align-items: center;

  height: 30vh;
  width: 100%;

  .c {
    .h {
      color: #19202f;
      font-family: Inter;
      text-align: center;
      font-size: 15px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      margin-bottom: 1rem;
    }
    .s {
      color: #aaa;
      text-align: center;
      font-family: Inter;
      font-size: 11px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
    }
  }
}
