@use '../../_mixins.scss' as *;

.label {
  color: #aaa;
  font-family: Inter;
  font-size: 8px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;

  color: #313131;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.txarea {
  margin: 10px 0px;
  border-radius: 10px;
  background: #eeeef0;

  /*padding: 16px 29px;
  */
  padding: $input-con-padding;

  textarea {
    outline: none;
    resize: none;
    width: 100%;
    border: 0px solid transparent;
    background: transparent;

    @include placeholder {
      color: #6b6b6b;
      font-family: Inter;
      font-size: 12px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }
  }
}
