a {
  text-decoration: none;
}
.b {
  padding: 1rem;
  border-radius: 20px;
  background: #fff;

  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;

  .l {
    display: grid;
    grid-template-columns: 36px auto;
    gap: 0.5rem;
    align-items: center;

    .icon {
    }

    .details {
      text-transform: capitalize;
      .h {
        color: #313131;
        font-family: Inter;
        font-size: 12px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
      }
      .s {
        color: #8f8f8f;
        font-family: Inter;
        font-size: 8px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }
    }
  }

  .r {
    display: grid;
    grid-template-columns: auto 35px;
    gap: 0.5rem;

    .tx {
      color: #c40000;
      font-family: Inter;
      font-size: 8px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      margin-top: 0.5rem;
    }

    .icon {
      width: 35px;
      height: 35px;
      flex-shrink: 0;
      background: url('data:image/svg+xml,<svg width="35" height="35" viewBox="0 0 35 35" fill="none" xmlns="http://www.w3.org/2000/svg"><circle cx="17.5" cy="17.5" r="17.5" fill="%236747C7" fill-opacity="0.24"/><path d="M16 11L23 18L16 25" stroke="%236747C7" stroke-linecap="round" stroke-linejoin="round"/></svg>');
    }
  }
}
