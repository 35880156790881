@use '../../_mixins.scss' as *;

.in {
  border-radius: 10px;
  background: #6747c7;
  color: #fff;
  /*
  color: #313131;*/
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;

  padding: $input-con-padding;
  text-align: center;
  margin: 15px 0px; /*
  margin: 26px 0px;*/
  border: 0px solid transparent;
  cursor: pointer;
}

input.in:disabled {
  background: #aeaeae;
}
