.s {
  color: #19202f;
  font-family: Inter;
  font-size: 10px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin: 0.4rem 0;
}

.empty {
  display: grid;
  justify-content: center;
  align-items: center;

  height: 30vh;
  width: 100%;

  .c {
    .h {
      color: #19202f;
      font-family: Inter;
      text-align: center;
      font-size: 15px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      margin-bottom: 1rem;
    }
    .s {
      color: #aaa;
      text-align: center;
      font-family: Inter;
      font-size: 11px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
    }
  }
}
.box {
  border-radius: 25px;
  background: #fff;

  display: flex;
  justify-content: space-between;
  padding: 32px 26px;

  .box1 {
    .h {
      color: #b7b7b7;
      font-family: Inter;
      font-size: 10px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
    .h2 {
      color: #313131;
      font-family: Inter;
      font-size: 25px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
    }

    .h3 {
      display: flex;
      gap: 0.5rem;
      align-items: center;
      color: #313131;
      font-family: Inter;
      font-size: 10px;
      font-style: normal;
      font-weight: 300;
      line-height: normal;
      .b {
        font-weight: 400;
      }
      .copy {
        width: 28.903px;
        height: 28.903px;
        flex-shrink: 0;
        background: url('data:image/svg+xml,<svg width="29" height="29" viewBox="0 0 29 29" fill="none" xmlns="http://www.w3.org/2000/svg"><circle cx="14.4514" cy="14.4514" r="14.4514" fill="%233D3D3D" fill-opacity="0.53"/><path d="M14.1046 8.09192C13.6754 8.09192 13.2637 8.26245 12.9601 8.56598C12.6566 8.86952 12.4861 9.28121 12.4861 9.71048V16.1847C12.4861 16.614 12.6566 17.0257 12.9601 17.3292C13.2637 17.6328 13.6754 17.8033 14.1046 17.8033H18.9603C19.3896 17.8033 19.8013 17.6328 20.1048 17.3292C20.4084 17.0257 20.5789 16.614 20.5789 16.1847V11.6641C20.5788 11.2348 20.4082 10.8232 20.1046 10.5198L18.151 8.56616C17.8476 8.2626 17.436 8.09201 17.0067 8.09192H14.1046Z" fill="%231199FA"/><path d="M9.24902 12.9485C9.24902 12.5192 9.41955 12.1075 9.72309 11.804C10.0266 11.5004 10.4383 11.3299 10.8676 11.3299V19.4227H17.3418C17.3418 19.852 17.1713 20.2637 16.8678 20.5672C16.5642 20.8707 16.1525 21.0413 15.7233 21.0413H10.8676C10.4383 21.0413 10.0266 20.8707 9.72309 20.5672C9.41955 20.2637 9.24902 19.852 9.24902 19.4227V12.9485Z" fill="%231199FA"/></svg>');
      }
    }
  }

  .box2 {
    .xx {
      width: 20px;
      height: 18px;
      flex-shrink: 0;
      background: url('data:image/svg+xml,<svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M12.418 16.825C11.7999 16.9419 11.1721 17.0005 10.543 17C6.065 17 2.275 14.057 1 10C1.34327 8.9081 1.87199 7.88346 2.563 6.971M12.663 12.12L8.421 7.879C8.98366 7.31634 9.74678 7.00025 10.5425 7.00025C11.3382 7.00025 12.1013 7.31634 12.664 7.879C13.2267 8.44166 13.5428 9.20478 13.5428 10.0005C13.5428 10.7962 13.2267 11.5593 12.664 12.122L15.955 15.412M8.423 7.88L5.133 4.59M5.133 4.59L1.543 1M5.133 4.59C6.74536 3.54957 8.6241 2.9974 10.543 3C15.021 3 18.811 5.943 20.086 10C19.382 12.2305 17.9204 14.1446 15.954 15.411L19.543 19" stroke="%23313131" stroke-linecap="round" stroke-linejoin="round"/></svg>');
    }
  }
}
