@use '../../_mixins.scss' as *;

.label {
  color: #aaa;
  font-family: Inter;
  font-size: 8px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;

  color: #313131;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.c {
  margin: 10px 0px;
  margin-bottom: 5px;
  border-radius: 10px;
  background: #eeeef0; /*
  padding: 10px;*/

  padding: $input-con-padding;

  display: grid;
  grid-template-columns: 1fr;
  gap: 1rem;

  input {
    color: #19202f;
    font-family: Inter;
    font-size: 13px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    width: 100%;

    background: transparent;
    outline: none;
    border: 0px solid transparent;
  }
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus {
    -webkit-text-fill-color: #19202f;
    -webkit-box-shadow: 0 0 0px 40rem #eeeef0 inset;
  }
}
