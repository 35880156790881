@use '../../_mixins.scss' as *;

.label {
  color: #313131;
  font-family: Inter;
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.came {
  width: 20px;
  height: 18px;
  flex-shrink: 0;
  background: url('data:image/svg+xml,<svg width="20" height="18" viewBox="0 0 20 18" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M2.67121 18H17.3288C19.0982 18 20 17.0184 20 15.0739V5.19142C20 3.24702 19.0982 2.2748 17.3288 2.2748H15.3381C14.6746 2.2748 14.4704 2.12378 14.0876 1.65182L13.3986 0.802311C12.9817 0.283197 12.5479 0 11.6631 0H8.27733C7.40109 0 6.96724 0.283197 6.54192 0.802311L5.85282 1.65182C5.47853 2.11431 5.26587 2.2748 4.6023 2.2748H2.67121C0.901752 2.2748 0 3.24702 0 5.19142V15.0739C0 17.0184 0.901752 18 2.67121 18ZM2.69673 16.4803C1.85454 16.4803 1.36965 15.9801 1.36965 14.9984V5.27636C1.36965 4.29471 1.85454 3.79444 2.69673 3.79444H4.94258C5.70822 3.79444 6.11656 3.634 6.54192 3.10542L7.21395 2.2748C7.69887 1.67071 7.94554 1.51969 8.70269 1.51969H11.2378C11.9949 1.51969 12.2416 1.67071 12.7265 2.2748L13.3986 3.10542C13.8239 3.634 14.2323 3.79444 14.9979 3.79444H17.3033C18.1455 3.79444 18.6304 4.29471 18.6304 5.27636V14.9984C18.6304 15.9801 18.1455 16.4803 17.3033 16.4803H2.69673ZM9.99575 14.9796C12.5734 14.9796 14.6406 12.6859 14.6406 9.81645C14.6406 6.94702 12.5819 4.65342 9.99575 4.65342C7.42664 4.65342 5.35945 6.94702 5.35945 9.81645C5.35945 12.6859 7.42664 14.9796 9.99575 14.9796ZM16.1123 7.51338C16.6908 7.51338 17.1671 6.99422 17.1671 6.3524C17.1671 5.70111 16.6908 5.18196 16.1123 5.18196C15.5338 5.18196 15.0574 5.70111 15.0574 6.3524C15.0574 6.99422 15.5338 7.51338 16.1123 7.51338ZM9.99575 13.5448C8.14974 13.5448 6.64398 11.8836 6.64398 9.81645C6.64398 7.74934 8.14122 6.08809 9.99575 6.08809C11.8503 6.08809 13.356 7.74934 13.356 9.81645C13.356 11.8836 11.8503 13.5448 9.99575 13.5448Z" fill="black"/></svg>');
}

.c {
  margin: 10px 0px;
  width: 100%;
  height: 194px;
  flex-shrink: 0;

  display: flex;
  justify-content: center;
  align-items: center;

  &.small {
    margin-right: 1rem !important;
    justify-content: flex-start;
    padding-right: 0px;

    height: auto;
  }

  border-radius: 20px;
  border: 1px solid #aaa;
  background: rgba(255, 255, 255, 0);

  /*border-radius: 10px;
  border: 1px solid rgba(145, 165, 186, 0.28);
  background: ;
*/
  .threeV2 {
    margin: $input-con-padding;
    display: flex;
    justify-items: flex-start;
    align-items: center;
    width: auto;
    gap: 1rem;
    .icon {
      width: 30.601px;
      height: 29.296px;
      flex-shrink: 0;
      background: url('data:image/svg+xml,<svg width="31" height="32" viewBox="0 0 31 32" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M8.95599 23.7837C6.97315 23.785 5.06139 23.0277 3.59581 21.6602C2.13022 20.2927 1.2166 18.4138 1.03421 16.3922C0.851821 14.3706 1.41383 12.3521 2.60996 10.7329C3.80609 9.11363 5.55 8.0105 7.49952 7.63992C7.84194 5.77363 8.81058 4.08855 10.2379 2.87608C11.6652 1.66362 13.4615 1 15.3161 1C17.1707 1 18.967 1.66362 20.3943 2.87608C21.8216 4.08855 22.7903 5.77363 23.1327 7.63992C25.0757 8.01804 26.8114 9.12384 28.0011 10.7415C29.1908 12.3593 29.7492 14.3728 29.5673 16.3893C29.3854 18.4058 28.4762 20.2805 27.0172 21.6477C25.5582 23.0149 23.654 23.7764 21.6762 23.7837M10.546 15.6434L15.3161 10.7593M15.3161 10.7593L20.0862 15.6434M15.3161 10.7593V30.2959" stroke="%23AAAAAA" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg>');
    }
    .t2 {
      /*
      color: #0574ef;*/
      font-family: Inter;
      font-size: 13px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
    }
    .t3 {
      color: #313131;
      font-family: Inter;
      font-size: 10px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }
  }

  .three {
    display: flex;
    justify-items: center;
    align-items: center;
    flex-direction: column;
    .icon {
      width: 30.601px;
      height: 29.296px;
      flex-shrink: 0;
      background: url('data:image/svg+xml,<svg width="31" height="32" viewBox="0 0 31 32" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M8.95599 23.7837C6.97315 23.785 5.06139 23.0277 3.59581 21.6602C2.13022 20.2927 1.2166 18.4138 1.03421 16.3922C0.851821 14.3706 1.41383 12.3521 2.60996 10.7329C3.80609 9.11363 5.55 8.0105 7.49952 7.63992C7.84194 5.77363 8.81058 4.08855 10.2379 2.87608C11.6652 1.66362 13.4615 1 15.3161 1C17.1707 1 18.967 1.66362 20.3943 2.87608C21.8216 4.08855 22.7903 5.77363 23.1327 7.63992C25.0757 8.01804 26.8114 9.12384 28.0011 10.7415C29.1908 12.3593 29.7492 14.3728 29.5673 16.3893C29.3854 18.4058 28.4762 20.2805 27.0172 21.6477C25.5582 23.0149 23.654 23.7764 21.6762 23.7837M10.546 15.6434L15.3161 10.7593M15.3161 10.7593L20.0862 15.6434M15.3161 10.7593V30.2959" stroke="%23AAAAAA" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg>');
    }
    .t2 {
      color: #0574ef;
      font-family: Inter;
      font-size: 13px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
    }
    .t3 {
      color: #313131;
      font-family: Inter;
      font-size: 10px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }
  }
}
