.z {
  /*
  position:sticky;
  margin-top: 11rem;*/
  bottom: 10px;
  left: 0;
  width: 100%;

  .b {
    max-width: 500px;
    margin: auto;

    padding: 24px;

    .c {
      border-radius: 12px;
      background: #fff;

      padding: 1rem;

      color: #313131;
      font-family: Inter;
      font-size: 12px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;

      text-align: center;
    }
  }
}
