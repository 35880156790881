@use '../../../_mixins.scss' as *;

.h {
  color: #19202f;
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-bottom: 1rem;
  padding-left: $left-shift;
}

.duo {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1rem;
  margin-bottom: 0.5rem;
}

.profcon {
  display: flex;
  justify-content: space-between;

  .profduo {
    display: grid;
    grid-template-columns: 39px 1fr;
    gap: 1rem;

    .i {
      height: 39px;
      width: 39px;

      background: url('data:image/svg+xml,<svg width="39" height="39" viewBox="0 0 39 39" fill="none" xmlns="http://www.w3.org/2000/svg"><g clip-path="url(%23clip0_0_16)"><mask id="mask0_0_16" style="mask-type:luminance" maskUnits="userSpaceOnUse" x="0" y="0" width="39" height="39"><path d="M38.5105 0H0V38.5105H38.5105V0Z" fill="white"/></mask><g mask="url(%23mask0_0_16)"><path opacity="0.4" d="M19.2552 38.0076C29.8895 38.0076 38.5105 29.5576 38.5105 19.1337C38.5105 8.70989 29.8895 0.259735 19.2552 0.259735C8.62085 0.259735 0 8.70989 0 19.1337C0 29.5576 8.62085 38.0076 19.2552 38.0076Z" fill="%23AAAAAA"/><path d="M19.2551 9.56436C15.2693 9.56436 12.0344 12.7352 12.0344 16.642C12.0344 20.4735 15.096 23.5877 19.1589 23.7009H19.3322H19.4669H19.5055C23.395 23.5688 26.4566 20.4735 26.4758 16.642C26.4758 12.7352 23.241 9.56436 19.2551 9.56436Z" fill="%23EEEEF0"/><path d="M32.3118 33.0059C28.8843 36.1013 24.3016 38.0076 19.2567 38.0076C14.2118 38.0076 9.6291 36.1013 6.20166 33.0059C6.66379 31.2885 7.91537 29.7219 9.74463 28.514C15.0013 25.0789 23.5506 25.0789 28.7688 28.514C30.6173 29.7219 31.8496 31.2885 32.3118 33.0059Z" fill="%23EEEEF0"/></g></g><defs><clipPath id="clip0_0_16"><rect width="38.5105" height="38.5105" fill="white"/></clipPath></defs></svg>');
      img {
        object-fit: cover;
        background: grey;
        border-radius: 50%;
        width: 100%;
        height: 100%;
      }
    }

    .list {
      .li1 {
        color: #313131;
        font-family: Inter;
        font-size: 15px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
      }
      .li2 {
        color: #313131;
        font-family: Inter;
        font-size: 10px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        text-decoration-line: underline;
      }
    }
  }

  .btn {
    border-radius: 10px;
    background: #6747c7;
    padding: 1rem;

    color: #fff;
    font-family: Inter;
    font-size: 10px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
}
