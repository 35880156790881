@use '../../_mixins.scss' as *;

.label {
  color: #313131;
  font-family: Inter;
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.c {
  margin: 10px 0px;
  border-radius: 10px; /*
  background: #eeeef0;*/

  padding: $input-con-padding;
  margin-bottom: 1rem;
  border-radius: 20px;
  border: 1px solid #aaa;

  display: grid;
  grid-template-columns: 22px 1fr 16px;
  gap: 1rem;
  align-items: center;

  overflow: hidden;

  select {
    color: #19202f;
    font-family: Inter;
    font-size: 13px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    width: 100%;

    background: transparent;
    outline: none;
    border: 0px solid transparent;

    text-overflow: '';
    text-indent: 0.01px; /* Removes default arrow from firefox*/
    text-overflow: ''; /*Removes default arrow from firefox*/

    background-image: none;
    background-image: url('');
  }

  /*https://stackoverflow.com/questions/16603979/how-to-remove-the-default-arrow-icon-from-a-dropdown-list-select-element*/
  select {
    /* for Firefox */
    -moz-appearance: none;
    /* for Chrome */
    -webkit-appearance: none;
    appearance: none;
  }

  /* For IE10 */
  select::-ms-expand {
    display: none;
  }

  .e {
    display: flex;
    cursor: pointer;
    .icon {
      width: 16px;
      height: 10px;
      flex-shrink: 0;
      background: url('data:image/svg+xml,<svg width="16" height="11" viewBox="0 0 16 11" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M15 1.93719L8 9.26116L1 1.93719" stroke="%23AEAEAE" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg>');
    }
  }
}
