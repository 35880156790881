@use '../../_mixins.scss' as *;

.okay {
  margin-top: 19px;
  border-radius: 10px;
  background: #6747c7;

  color: #fff;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-align: center;

  padding: $input-con-padding;
}

.bg {
  height: 0%;
  width: 0%;
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  display: none;

  max-width: 500px;
  margin: auto;

  &.displayN {
    height: 100vh;

    /**/
    width: 100%;
    margin-top: 1rem; /*
    margin-left: 1rem;*/
    display: flex !important; /*
    justify-content: center;*/
    height: auto;

    .con {
      border-radius: 11px;
      background: #fff;
      box-shadow: 0px 0px 4px 5px rgba(0, 0, 0, 0.03);

      padding: 1rem;

      width: 100%;
      margin: 1rem;
      margin-top: 4rem;

      .h {
        margin-top: 42px;
        color: #000;
        font-family: Inter;
        font-size: 25px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
      }

      .b {
        margin-top: 9px;
        color: #19202f;
        font-family: Inter;
        font-size: 10px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }
    }
  }
}
