.card {
  .top {
    border-radius: 14px 14px 0px 0px;
    background: #6747c7;
    padding: 24px;

    color: #fff;
    font-family: Inter;
    font-size: 13px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }

  .b {
    padding: 28px;
    border-radius: 14px;
    background: #fff;
  }
  margin-bottom: 1rem;
}
